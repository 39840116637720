<template>
    <div id="dc-login">
        <div id="dc-form-container">
            <img src="./../../assets/logo.png" />
            <h1>Admin</h1>
            <form @submit.prevent="onClickLogin">
                <b-form-group id="dc-email-container" label="Username">
                    <b-form-input v-model="form.username" placeholder="Enter username"></b-form-input>
                </b-form-group>
                <b-form-group id="dc-password-container" label="Password">
                    <b-form-input v-model="form.password" type="password" placeholder="Enter password"></b-form-input>
                </b-form-group>
                <b-button class="dc-button-primary" type="submit">Login</b-button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { login_endpoint } from "./../../constant/api-endpoints";

export default {
    name: "Login",
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
        };
    },
    methods: {
        checkToken() {
            let token = localStorage.getItem("api_token");
            if (!token || token.trim().length === 0) {
                console.log("No token found");
            } else {
                // console.log("Token found", token);
            }
        },
        onClickLogin() {
            this.validateLogin();
        },
        async validateLogin() {
            let params = {
                username: this.form.username.trim(),
                password: this.form.password.trim(),
            };

            axios
                .post(login_endpoint, params)
                .then((res) => {
                    localStorage.setItem("api_token", res.data.accessToken);
                    localStorage.setItem("admin_displayname", res.data.displayname);
                    this.$router.push({ path: "/woocommerce" });
                })
                .catch((err) => {
                    console.log(err);
                    this.$toasted.show("Invalid Login", { position: "bottom-center", className: "dc-toast-success", duration: 4000 });
                });
        },
    },
    mounted() {
        this.checkToken();
    },
};
</script>

<style lang="scss">
#dc-login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    #dc-form-container {
        background-color: #fff;
        border-radius: 10px;
        width: 450px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 200px;
        }

        h1 {
            margin: 20px 0px;
        }
        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .form-group {
            width: 80%;
            margin-top: 10px;
        }

        button {
            margin-top: 20px;
            width: 140px;
            font-size: 20px;
            font-weight: bold;
        }
    }
}
</style>
